.cookies-banner {
    position: fixed !important;
    bottom: 0 !important;
    z-index: 2000 !important;
    padding: 5% 10%;
    line-height: 28px;
    background-color: rgba(236, 236, 236, 0.7);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
}

.cookies-wrapper {
    flex: 1;
}

.cookies-btn {
    height: 3rem;
    border-radius: 50px;
    font-size: large;
    font-weight: bold;
    color: white;
    background-color: #b11116;
    cursor: pointer;
    width: 300px;
    margin-top: 10px;
}
