.App {
    background-color: #ebecf0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Arial', sans-serif !important;
}

p {
    line-height: 20px !important;
}

.pp-card {
    position: relative;
    top: -140px !important;
    z-index: 100 !important;
    border-radius: 15px !important;
    width: 700px !important;
    padding: 20px;
    background-color: white;
    box-shadow: 0 5px 13px 0 rgba(26, 26, 26, 0.2) !important;
    margin-bottom: 40px;
}

.default {
    height: 18px !important;
    width: 18px !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    margin: 10px 0 5px 0;
}
body {
    margin: 0;
    font-family: 'Arial', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.input-container {
    text-align: left;
}

input {
    margin-top: 0.5rem;
}

.pp-error {
    margin: 0 0 0.25rem 0;
    color: #c8050b !important;
    font-size: 0.8rem !important;
    padding-top: 5px;
}

button {
    width: 100%;
}

.pp-navbar {
    height: 80px;
    background-color: #b11116;
    background-image: url('https://images.unsplash.com/photo-1527769929977-c341ee9f2033?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80');
    object-fit: cover;
    z-index: 20 !important;
}

.box {
    position: relative;
    min-height: 180px;
    box-shadow: 0 5px 13px 0 rgba(26, 26, 26, 0.03);
    min-width: 200px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.grid > div:hover {
    outline: rgba(177, 17, 22, 0.37) solid 5px;
    transition: 0.3s;
    color: #b11116;
}
.grid > div {
    width: 190px;
    margin: 20px auto;
    -webkit-box-shadow: 0 5px 13px 0 rgba(26, 26, 26, 0.09);
    min-height: 180px !important ;
    background: white;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 6px 10px;
    font-size: 20px !important;
    font-weight: 800;
    line-break: strict;
    line-height: 30px;
    color: #2d2f34;
    text-align: center;
    outline: #f1f1f1 solid 1px;
}

.pp-tab button {
    border-radius: 8px;
}

.pp-tab button:hover {
    color: #b11116;
}

.pp-tab button.Mui-selected {
    background-color: white;
    color: #b11116;
    margin: 0 4px;
    font-weight: bold;
}

.css-1gsv261 {
    background-color: #ececec;
    border-radius: 10px;
    padding: 4px !important;
}

.pp-card-header {
    border-radius: 0.37rem 0.37rem 0 0;
    padding: 0.5rem;
    border: gray 0.01em solid;
    background-color: #f3f3f3;
    text-align: center !important;
}

.pp-card-body {
    border-radius: 0 0 0.37rem 0.37rem;
    padding: 1.5rem;
    border: gray 0.01em solid;
    border-top: none;
}

.css-19kzrtu {
    padding: 0 !important;
    margin-top: 15px;
    margin-bottom: 20px;
}

.form-control {
    margin: 15px 0 !important;
}

.upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-radius: 10px;
    border: #b11116 1px dashed !important;
    cursor: pointer;
    color: #b11116 !important;
    margin-top: 30px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.upload-btn:hover {
    background-color: #b11116 !important;
    color: white !important;
}

.pp-tab-short {
    width: 20%;
}

.secondary-btn {
    border-width: 1px !important;
    border-style: solid !important;
    padding: 25px 23px !important;
}

.log-card {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    background-color: white;
    margin-bottom: 20px;
    min-height: 90px !important;
}

.my-2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.center {
    text-align: center;
}

.footer {
    font-size: 14px !important;
    line-height: 18px !important;
    background-color: #eeeeee;
    padding: 3rem 3rem 1rem 3rem;
    z-index: 2000 !important;
}

.pr-text {
    font-size: small !important;
    margin: 0 5px !important;
}

.pr-text::before {
    content: '·';
    padding-right: 10px;
}
.pr-text:hover {
    text-decoration: none !important;
    color: #b11116;
}

.pr-content {
    list-style-type: none;
    text-decoration: none !important;
}

.nav-bar {
    background-color: #b11116;
    height: 300px;
    background-image: url('/public/banner.webp');
    background-size: cover;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 0 !important;
}

.subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.2rem;
    margin-top: 5px !important;
}

.border {
    border: #b11116 solid 1px;
}

.btn-icon {
    padding-top: 20px;
    width: 100px !important;
    margin: auto !important;
    height: 100px;
    object-fit: contain !important;
}

.card-media {
    object-fit: contain;
    padding-bottom: 20px;
}

.body-7-bold {
    font-weight: 500;
    font-size: 1rem;
    color: black;
    white-space: nowrap;
}

.css-rzfh0s-MuiTypography-root {
    font-size: 0.8rem !important;
}

.back-button {
    fill: white !important;
    margin-left: 8px !important;
    margin-top: 3px !important;
}

.right-align {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.zi-unset::placeholder {
    background-color: white !important;
    z-index: unset !important;
}

.tab-width {
    width: 16.4% !important;
}

.tab-header {
    background-color: #f2f2f2;
    border-radius: 6px !important;
    padding: 0.5rem 1rem 1rem 0.5rem;
}

.radio-grp {
    padding: 1rem !important;
    margin-bottom: 5px;
    border-radius: 8px !important;
    width: 300px;
}

.hour-card {
    border: 1px solid rgb(221, 221, 221) !important;
    border-radius: 12px !important;
    box-shadow: unset !important;
    background-color: white !important;
    min-height: 130px !important;
    padding: 10px;
}

.hr-desc {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    margin: 0 !important;
    white-space: pre-wrap;
}

.hr-log {
    min-height: 10px !important;
    font-weight: 800 !important;
    font-size: 3rem !important;
    margin: 0 !important;
    padding-left: 3px !important;
    line-height: 4rem !important;
}

.hr-log::before {
    border-bottom: transparent !important;
}
.hr-log::after {
    border-bottom: transparent !important;
}

.hr-log::placeholder {
    opacity: 0.2 !important;
}

.price {
    min-height: 10px !important;
    font-weight: 800 !important;
    font-size: 2.8rem !important;
    padding-top: 0.4rem;
    line-height: 4rem !important;
}

.pp-date {
    height: 55px !important;
    border-radius: 5px;
    border: 1px lightgray solid;
    padding-left: 15px;
    font-size: 16px;
    margin-top: 0 !important;
    margin-bottom: 20px;
    width: 90% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: #b11116 !important;
}

.pp-btn-back:disabled {
    color: #8e8e8e !important;
    outline: unset !important;
    background-color: rgba(211, 211, 211, 0.5);
}

.react-datepicker__day--keyboard-selected {
    background-color: #b11116 !important;
}

.zi-unset::placeholder {
    z-index: unset !important;
}

.react-datepicker {
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 20px !important;
    font-size: 0.9rem !important;
}

.pp-menu {
    height: 35rem;
    backdrop-filter: blur(20px) !important;
    font-size: larger !important;
    transition: unset !important;
}

.pp-menu:hover {
    background-color: unset !important;
}

.pp-menu-children {
    transition: unset !important;
    padding-right: 3rem !important;
}

.pp-menu-children:hover {
    background-color: rgba(177, 17, 22, 0.88) !important;
    border-radius: 10px !important;
    margin: 5px !important;
    color: white !important;
    font-weight: bold !important;
}

.gray-bg {
    background-color: #eaeaea;
    margin-right: 10px !important;
    border-radius: 8px;
    padding: 10px;
}

.tb {
    border-bottom: 1px solid lightgray !important;
    display: flex;
    padding: 20px 0;
}

.cell {
    margin-left: auto !important;
    font-weight: bold;
    margin-right: 0;
}
.empl-copy {
    margin: 0;
}

.pa-no {
    font-size: large;
    font-weight: bolder;
    align-content: center;
    text-align: center;
    background-color: #b11116;
    color: white;
}

.submit-bg {
    background-image: url('/public/icons/success-bg.svg');
    min-height: 23rem;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    right: -24px !important;
    bottom: -24px;
}
.footer-box {
    padding: 15px;
    background-color: #f0f0f0;
    font-size: 14px;
    line-height: 20px;
    border-radius: 10px;
}

.bold-border {
    margin-bottom: 1rem;
    height: 8px;
    background-color: #b11116;
    width: 50%;
    border-radius: 5px;
}

table {
    margin-bottom: 20px;
    box-shadow: 0 5px 13px 0 rgba(26, 26, 26, 0.2);
    border-radius: 10px;
    padding-bottom: 15px;
}
tbody {
    padding: 10px;
}
td {
    padding: 10px;
    border-bottom: solid 1px rgba(128, 128, 128, 0.3) !important;
}
td:last-child {
    padding: 10px;
    border-right: none !important;
}
tr {
    padding: 5px;
}
th {
    background-color: #b11116;
    padding: 10px;
    color: white;
    border-radius: 10px;
}

table tr:last-child td {
    border-bottom: none !important;
}

.hidden {
    visibility: hidden;
    height: 10px !important;
}

.checkbox {
    background-color: rgb(251, 251, 251);
    padding: 20px;
    border: 0.2px solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    margin: auto;
}

.warning-card {
    background-color: rgba(255, 249, 235, 0.5) !important;
    border-color: rgb(209, 142, 0) !important;
    margin: 0 0 15px 0 !important;
}

.table-bg {
    background-color: rgba(189, 189, 189, 0.2);
    padding: 15px 0;
}

.checked {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    width: 25px;
}

.radio-card {
    background-color: #fbfbfb;
    padding: 5px;
    border: 1px solid rgba(128, 128, 128, 0.42);
    border-radius: 10px;
    margin: 0;
}

.date-picker {
    width:100%;
    font-family: 'Arial', sans-serif !important;
    height:25px;
    padding:10px!important;
    border:1px solid rgba(128,128,128,0.55);
    text-transform: uppercase;
    border-radius:5px;
    font-size:16px;
    -webkit-appearance: none;
}

.extra-hr {
    height: 50px;
    top: -7px !important;
    position: relative;
    font-size: medium;
    padding-left: 10px;
    border-radius: 5px;
    width: 90%;
    border: 1px solid lightgray;
}

@media only screen and (max-width: 600px) {
    .pp-tab-item {
        width: 50%;
    }

    .pp-card-body {
        border-radius: 0 0 0.37rem 0.37rem;
        padding: 10px;
    }

    .log-card {
        padding: 15px 10px;
    }

    .dept-btn {
        width: 145px !important;
        font-size: 16px !important;
        line-height: 23px;
        padding: 20px;
    }

    .css-mnn31 {
        font-size: 2.5rem !important;
    }

    .rdp {
        --rdp-cell-size: 40px !important;
    }

    .empl-copy {
        margin-top: 40px;
    }

    .pp-card {
        padding: 8px;
    }

    .nav-bar {
        background-size: contain;
        background-color: #b11116;
        height: 200px;
        background-repeat: no-repeat;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 0 !important;
    }
}
